<template>
    <form @submit.prevent="handleSubmit" class="add">
        <div class="kilometrage">
            <label>Kilometerstand:</label>
            <input
                type="number"
                v-model="kilometrage"
                :min="props.lastKilometrage"
                step="0.1"
                required
            />
        </div>
        <div class="liters">
            <label>Getankte Liter:</label>
            <input
                type="number"
                v-model="liters"
                step="0.1"
                min="0.0"
                max="10000.00"
                required
            />
        </div>
        <div class="price">
            <label>Gezahlter Preis:</label>
            <input
                type="number"
                v-model="price"
                min="0.00"
                max="10000.00"
                step="0.01"
                required
            />
        </div>
        <div class="date">
            <label>Datum:</label>
            <input type="date" v-model="date" required />
        </div>
        <button>Hinzufügen</button>
        <button @click.prevent="$emit('abort')">Abbrechen</button>
    </form>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/firebase/config';

const emit = defineEmits(['submit', 'abort']);

const props = defineProps(['lastKilometrage']);

const kilometrage = ref(props.lastKilometrage + 1);
const liters = ref(null);
const price = ref(null);

const defaultDate = new Date().toISOString().split('T')[0];

const date = ref(defaultDate);

const handleSubmit = async () => {
    emit('submit');
    const kilometers = kilometrage.value - props.lastKilometrage;

    const newRefuel = {
        kilometrage: kilometrage.value,
        liters: liters.value,
        price: price.value,
        kilometers: kilometers,
        date: Timestamp.fromDate(new Date(date.value)),
    };

    await addDoc(collection(db, 'refuels'), newRefuel);
};
</script>

<style>

.add {
    border: 1px dashed rgba(72, 80, 73, 0.933);
    padding: 5px;
    border-radius: 5px;
}
label {
    margin: 10px 5px 0;
}

input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 2px solid #eeeeeec4;
    border-radius: 10px;
}

button {
    margin-top: 10px;
    width: 100%;
    background: #c4bfb9;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 18px;
    border-radius: 10px;
}

button:hover {
    background: rgb(233, 171, 83);
}
</style>
