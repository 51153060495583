import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyCGAcGGxxMa_V_RA_ix7KUqkrGSNkg4YHE",
    authDomain: "vue-firebase-sites-54a95.firebaseapp.com",
    projectId: "vue-firebase-sites-54a95",
    storageBucket: "vue-firebase-sites-54a95.appspot.com",
    messagingSenderId: "170611657671",
    appId: "1:170611657671:web:728e7929810281764327e2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export {db}
