<template>
    <div>
        <ul>
            <li v-for="fuelEntry in reversedRefuels">
                <FuelEntry :entry="fuelEntry" />
            </li>
        </ul>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import FuelEntry from './FuelEntry.vue';
export default {
    props: ['refuels'],
    setup(props) {
        const reversedRefuels = computed(() => {
            return props.refuels.reverse();
        });

        return { reversedRefuels };
    },
    components: {
        FuelEntry,
    },
};
</script>

<style>
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
</style>
