<template>
    <div class="initial">
        <form @submit.prevent="handleSetInitial">
            <div>
                <label>Kilometerstand beim letzten Tankvorgang:</label>
                <input type="number" v-model="tempInitial" required />
            </div>

            <button>Weiter</button>
        </form>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['setInitial']);
const tempInitial = ref(null);

const handleSetInitial = () => {
    emit('setInitial', tempInitial);
};
</script>

<style>
.initial {
    color: rgb(233, 171, 83);
    font-weight: bold;
}

.initial label {
    margin: 10px 5px 0;
}

.initial input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 2px solid #eeeeeec4;
    border-radius: 10px;
}

.initial button {
    margin-top: 5px;
    background: #c4bfb9;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 18px;
    border-radius: 10px;
}

.initial button:hover {
    background: rgb(233, 171, 83);
}

.initial div {
}
</style>
