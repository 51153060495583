<template>
    <h1>Willkommen bei FuleMule</h1>
    <CreateInitial v-if="displayInitialForm" @setInitial="handleSetInitial" />
    <div class="refuels" v-else-if="loaded">
        <div
            class="plus"
            @click="toggleShowCreateComponent"
            v-if="!showCreateComponent"
        >
            <button>+</button>
        </div>
        <CreateRefuel
            :lastKilometrage="lastKilometrage"
            @abort="toggleShowCreateComponent"
            @submit="toggleShowCreateComponent"
            v-else
        />
        <div class="home">
            <FuelEntries :refuels="refuels" />
        </div>
    </div>
    <div v-else>Lade...</div>
</template>

<script setup>
import FuelEntries from '@/components/FuelEntries.vue';
import CreateInitial from '@/components/CreateInitial.vue';
import CreateRefuel from './components/CreateRefuel.vue';
import getRefuels from '@/composables/getRefuels';
import getInitial from '@/composables/getInitial';
import { collection, addDoc } from 'firebase/firestore';
import { computed, ref } from 'vue';
import { db } from './firebase/config';

const loaded = ref(false);
const { refuels, errorRefuels, loadRefuels } = getRefuels();
const { initial, errorInitial, loadInitial } = getInitial();

const loadData = async () => {
    await loadRefuels();
    if (!refuels.value.length) {
        await loadInitial();
    }
    loaded.value = true;
};
loadData();

const displayInitialForm = computed(() => {
    return loaded.value && !refuels.value.length && !initial.value;
});

const handleSetInitial = async (tempInitial) => {
    loaded.value = false;
    const initialKilometrage = {
        initial: true,
        kilometrage: tempInitial.value,
    };

    await addDoc(collection(db, 'refuels'), initialKilometrage);
    initial.value = tempInitial.value
    loaded.value = true;
};

const showCreateComponent = ref(false);

const toggleShowCreateComponent = () => {
    showCreateComponent.value = !showCreateComponent.value;
};

const lastKilometrage = computed(() => {
    if (refuels.value.length) {
        const kilometrages = refuels.value.map((item) => item.kilometrage);

        return Math.max(...kilometrages);
    }

    if (initial) {
        return initial.value;
    }

    return null;
});
</script>

<style>
h1 {
    color: #42b983;
    font-weight: bold;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

   
}
body {
  max-width: 600px;
  margin: 10px auto;
}

.plus {
    width: 100%;
}

.plus button {
    width: 100%;
    margin-top: 5px;
    background: #c4bfb9;
    color: white;
    border: none;
    padding: 20px 16px;
    font-size: 18px;
    border-radius: 10px;
    font-size: xx-large;
}

.plus button:hover {
    background: rgb(233, 171, 83);
}
</style>
