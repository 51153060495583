<template>
    <div class="fuelEntry">
        <h2>{{ entry.date.toDate().toLocaleDateString('de-DE') }}</h2>
        <div class="data">
            <p>{{ entry.kilometrage }}km</p>
            <p>{{ entry.liters }}l</p>
            <p class="price">{{ entry.price }}€</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['entry'],
    methods: {
        toISOString() {
            console.dir(this.entry.date);
        },
    },
};
</script>

<style>
.fuelEntry {
    border-style: solid;
    border-radius: 10px;
    font-size: large;
    padding: 10px;
    margin: 10px auto;
    max-width: 600px;
    min-width: min-content;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;

}

.fuelEntry h2 {
    margin-right: 20px;
}

.data {
    width: auto;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 390px) {
    .data {
        display: block;
        
    }
}
.fuelEntry p {
    height: auto;
    margin: 10px;
}

.fuelEntry:hover {
    background-color: rgb(233, 171, 83);
    transition: background-color 0.5s ease-out;
}

.price {
}
</style>
