import { db } from '@/firebase/config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { ref } from 'vue';

const getInitial = () => {
    const initial = ref(null);
    const errorInitial = ref(null);

    const loadInitial = async () => {
        try {
            const q = query(
                collection(db, 'refuels'),
                where('initial', '==', true)
            );
            const docs = (await getDocs(q)).docs
            if (docs.length){
                initial.value = docs[0].data().kilometrage
            }
            
        } catch (err) {
            errorInitial.value = err;
        }
    };

    return { initial, errorInitial, loadInitial };
};

export default getInitial;
