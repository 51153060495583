import { db } from '@/firebase/config';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { ref } from 'vue';

const getRefuels = () => {
    const refuels = ref([]);
    const errorRefuels = ref(null);

    const loadRefuels = async () => {
        try {
            const q = query(collection(db, 'refuels'), orderBy("date"));
            onSnapshot(q, (snap) => {
                refuels.value = snap.docs.map((doc) => doc.data());
            });
        } catch (err) {
            errorRefuels.value = err;
        }
    };

    return { refuels, errorRefuels, loadRefuels };
};

export default getRefuels;
